import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import Pagination from "../components/common/Pagination"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const VideosIndex = props => {
  const { data, pageContext } = props
  return (
    <Layout>
      <h1>
        All <span className="purple-underline">Videos</span>
      </h1>
      <div className="position-relative" style={{ zIndex: 2 }}>
        {data.drupal.nodeQuery.entities.map((entity, index) => {
          const releaseDate = entity?.releaseDate?.value || "Unknown"
          const entityUrl = entity?.entityUrl?.path
          return (
            <div className="row py-5" key={index}>
              <div className="col">
                <div className="py-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: entity?.embedCode }}
                    className="iframe-container"
                  />
                </div>
                <h3>
                  <Link to={entityUrl} className="white">
                    {entity?.entityLabel}
                  </Link>
                </h3>
                <div>{releaseDate}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="py-4">
        <Pagination pageContext={{ ...pageContext, pagePrefix: "videos" }} />
      </div>
      <div className="py-4" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/" className="white">
          Go back to the homepage
        </Link>
      </div>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    drupal {
      nodeQuery(
        offset: $skip
        limit: $limit
        filter: {
          conditions: [
            { field: "status", value: "1", operator: EQUAL }
            { field: "type", value: "video", operator: EQUAL }
          ]
        }
        sort: { field: "field_release_date", direction: DESC }
      ) {
        entities {
          entityId
          entityLabel
          entityUrl {
            path
          }
          ... on Default_NodeVideo {
            embedCode: fieldEmbedCode
            releaseDate: fieldReleaseDate {
              value
              date
            }
          }
        }
      }
    }
  }
`

export default VideosIndex
